html,
body {
  height: 100vh;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.wrap {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.pattern {
  width: 30%;
  height: 100vh;
  border-right: 9px solid #000;
}

.patternImg {
  object-fit: cover;
  height: 100%;
}

#to_gallery {
  position: absolute;
  top: 10px;
  right: 10px;

  color: #000;
  font-family: Rubik;
  font-size: 18px;
  text-decoration-line: underline;
}

.workspace {
  height: 100vh;
  width: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* background-color: rebeccapurple; */
}

.logoImg {
  margin: 0px 0px 50px 0px;
  object-fit: cover;
  width: 70%;

  /* width: 822px;
    height: 121px; */
}

.text {
  color: #000;
  font-family: Rubik;
  font-size: calc(2vw + 1.5vh + 1vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

#inputLogin {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  background-color: white;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;

  margin: 30px 0px;
  width: 40vw;
  height: 90px;
  border-radius: 68px;
  border: 1px solid #000;
}

#inputPass {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;

  margin: 30px 10px 30px 0px;
  height: 90px;
  width: 40vw;
  border-radius: 68px;
  border: 1px solid #000;
}

#inputLogin:focus {
  background-color: #fff;
}

.password-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 90px;
}

.pass-toggle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 40px;
  cursor: pointer;

}


#button_login {
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: calc(1vw + 1.5vh + 1vmin);
  font-weight: 400;
  line-height: normal;

  /*letter-spacing: 2.6px;*/
  margin: 25px 0px;
  width: 50%;
  height: 80px;
  align-self: center;

  border: none;
  border-radius: 69px;
  background: #00d076;
}

/*MOBILE*/
/*MOBILE*/
/*MOBILE*/

@media screen and (max-width: 525px) {
  .pattern {
    display: none;
  }

  .workspace {
    width: 100%;
  }

  .form-signin {
    width: 90%;
    align-items: center;
  }

  #inputPass,
  #inputLogin,
  #pass_and_eye {
    margin: 10px 0px;

    width: 75vw;
    height: 70px;
  }

  #button_login {
    height: 60px;
  }

  #eyes {
    margin-left: -55px;
    height: 35px;
  }
}