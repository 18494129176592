#avatar_back_modal {
  margin: 15px 20px;
  padding-top: 5px;
  height: 400px;
  width: 280px;
  background-color: white;
  border: none;
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#avatar_back_modal_active {
  margin: 15px 20px;
  padding-top: 5px;
  height: 400px;
  width: 280px;
  background-color: white;
  border: none;
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#avatar_cost {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#cost {
  color: black;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#gametoken {
  margin-left: 5px;
  width: 25px;
}

#button_pick_modal {
  margin-top: 10px;
  width: 50%;
  height: 30px;
  border-radius: 30px;
  border: none;
  background-color: #ffcf3a;

  color: white;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#button_pick_modal_active {
  margin-top: 10px;
  width: 50%;
  height: 30px;
  border-radius: 30px;
  border: none;
  background: #ffac3a;

  color: white;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#avatar_pick_modal {
  width: 200px;
  height: 300px;
  border-radius: 150px;
  object-fit: cover;
}

@media screen and (max-width: 525px) {
  #avatar_back_modal,
  #avatar_back_modal_active {
    height: 300px;
  }

  #avatar_pick_modal{
    width: 125px;
    height: 185px;
    border-radius: 150px;
  }
}
