#top {
  background: radial-gradient(
    52.05% 52.05% at 50% 47.95%,
    rgba(4, 0, 196, 0.46) 21.38%,
    #1f1ca6 100%
  );
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* display: grid;
    grid-template-areas: 
            "info pic stats";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr; */
}

#bottom {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#acc_wrapper {
  height: 92vh;
  /* background-color: aqua; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#pick_avatar_modal {
  margin-top: 30px;
  height: 80vh;
  /* background-color: #C3E4FF;  */

  display: flex;
  flex-direction: row;
}

#avatar_cat {
  overflow: auto;
  width: 20%;
  min-height: fit-content;
  max-height: 100%;
  /* background-color: #59A1E0; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  font-family: Rubik;
  color: #ffffff;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  font-style: normal;
}

.category {
  margin-top: 25px;
  width: 85%;
  height: 50px;

  background-color: rgba(90, 87, 255, 1);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: transform 0.15s ease-out;
  color: white;
}

.category_picked {
  margin-top: 25px;
  width: 85%;
  height: 50px;

  background: #00d076;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: transform 0.15s ease-out;
  color: white;
}

.category:hover {
  cursor: pointer;
  transform: scale(1.009);
}

#pick_avatar {
  overflow: auto;
  width: 80%;
  min-height: fit-content;
  max-height: 100%;
  /* background-color: aquamarine; */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#close_avatar_pick {
  position: absolute;
  right: 50px;
  top: 15px;
}

#info {
  z-index: 3;
  /* grid-area: info;  */
  padding-left: 50px;
  /* background-color: aqua; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 30%;
}

.acc_gradient {
  width: 100%;
  font-size: calc(0.5vw + 0.5vh + 0.7vmin);

  border-radius: 25px;
  /* background: radial-gradient(403.40% 133.13% at 4.09% 10.70%, rgba(255, 255, 255, 0.31) 0%, rgba(212, 225, 255, 0.00) 100%);
    backdrop-filter: blur(2px); */
  /* margin: 20px; */
  padding: 25px;
}

#profile {
  z-index: 3;
  /* grid-area: pic; */
  /* background-color: aliceblue; */
  height: 100%;
  /* width: 1fr; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#stats {
  z-index: 3;
  /* grid-area: stats; */
  padding-right: 50px;
  /* background-color: aquamarine; */
  /* width: 1fr; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

/* #info, #stats {
    margin-left: 35px;
} */

#name,
#surname {
  color: #ffffff;
  font-family: Rubik;
  font-size: 3.7em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#birthday {
  /* margin: 10px; */
  color: #ffffff;
  font-family: Rubik;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#my_groups {
  color: #ffffff;
  font-family: Rubik;
  font-size: 2.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#group {
  word-wrap: break-word;
  margin-left: 10px;
  color: #ffffff;
  font-family: Rubik;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#btn_picture {
  margin: 20px;
  width: 350px;
  height: 500px;
  border: none;
  border-radius: 200px;
  background-color: transparent;
  transition: transform 0.15s ease-out;
}

#btn_picture:hover {
  cursor: pointer;
  transform: scale(1.009);
}

#picture {
  object-fit: cover;
  width: 360px;
  height: 510px;
  border-radius: 200px;
  border: 10px solid white;
}

#exp {
  width: 100%;
}

#hint {
  margin-bottom: 10%;
  /* background-color: purple;  */
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#info_level {
  display: none;
  position: absolute;
  color: #ffffff;
  height: 25px;
  width: 489px;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#hint:hover #info_level {
  display: flex;
  cursor: grabbing;
}

#token {
  color: #ffffff;
  font-family: Rubik;
  font-size: calc(1vw + 1vh + 1.5vmin);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#about_token {
  /* background-color: plum ; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#calendar {
  padding: 10px;
  height: 45px;
  width: 80%;
  margin: 10px 0px;
  border-radius: 25px;
}

#info_token {
  /* background-color: pink; */
  /* padding: 20px; */
  /* width: 100%; */
  color: #ffffff;
  font-family: Rubik;
  font-size: calc(0.3vw + 0.3vh + 1vmin);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#level {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#number {
  margin-bottom: 10px;
  color: #ffffff;
  font-family: Rubik;
  font-size: calc(1vw + 1vh + 1.5vmin);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#account_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.account_head_button {
  border: none;
  color: black;
  background-color: transparent;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: Rubik;
  font-weight: 700;
}

.account_head_button_active {
  border: none;
  color: black;
  background-color: transparent;
  padding: 15px 32px;
  text-align: center;
  text-decoration: underline;
  display: inline-block;
  font-size: 22px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: Rubik;
  font-weight: 700;
}

.account_head_button:hover {
  text-decoration: underline;
}

.hw_words {
  width: 100%;
  padding-top: 10px;
  background-color: transparent;
  text-align: center;

  font-family: Rubik;
  color: white;
  font-size: 20px;
  font-weight: 700;
}

#right_wave {
  width: 30%;
  position: absolute;
  top: 5vh;
  right: 0;
  z-index: 1;
}

#left_wave {
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

#pre_style {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


@media screen and (max-width: 991px) {
  #btn_picture {
    height: 310px;
    width: 210px;
    border-radius: 100px;
    margin: 0;
  }

  #picture {
    height: 310px;
    width: 210px;
    border-radius: 100px;
    margin: 0;
  }
}

@media screen and (max-width: 525px) {

  #pre_style {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #top{
    display: grid;
    grid-template-areas: 
            "profile"  
            "info"
            "stats";

    grid-template-rows: 1fr 1fr 1.5fr;
    grid-template-columns: 1fr;
  }
  
  #profile {
    grid-area: profile;
    /* background-color: rebeccapurple; */
    padding: 0;
    margin-top: 15px;
    /* margin-left: 5px; */
    /* width: 50%; */
    /* height: 95vh; */

    /* position: absolute;
    left: 0; */

    justify-content: center;
    align-items: center;
  }

  #info {
    grid-area: info;
    /* position: absolute;
    right: 0;
    top: 0; */

    /* width: 50%; */
    max-width: 100%;
    /* max-height: 50vh; */

    /* margin-top: 5vh; */
    padding: 5px;
    /* margin-left: 20px; */
    margin: 5px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #stats {
    grid-area: stats;
    /* width: 70%; */
    /* height: 50vh; */
    justify-content: center;
    /* align-items: center; */

    padding: 2px;
    margin-top: 0;
    margin-right: 30px;
    /* position: absolute;
    right: 0;
    bottom: 0; */
  }

  #calendar {
    height: 35px;
  }

  
  
  .acc_gradient {
    padding: 0;
    font-size: calc(0.4vw + 0.4vh + 0.65vmin);
  }

  #hint{
    margin-bottom:  8px;
  }

  #number, #token {
    font-size: calc(1vw + 1vh + 1vmin);
    display: flex;
    
    align-self: center;
  }

  #about_token {
    flex-direction: column;
    
  }

  #btn_picture {
    height: 230px;
    width: 165px;
    border-radius: 100px;
    margin: 0;
  }

  #picture {
    height: 230px;
    width: 165px;
    border-radius: 100px;
    margin: 0;
  }

  #right_wave {
    width: 40%;
    position: absolute;
    top: 5vh;
    right: 0;
    z-index: 1;
  }
  
  #left_wave {
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  #my_groups {
    display: flex;
    justify-content: center;
  }

  /*STYLE FOR AVATAR PICK*/
  #pick_avatar_modal{
    /* background-color: tomato; */
    height: fit-content;
    flex-direction: column;
    justify-content: center;
  }

  #avatar_cat{
    width: auto;
    overflow: unset;
    font-size: calc(0.8vw + 0.8vh + 0.5vmin);
  }

  #pick_avatar{
    width: auto;
  }

  
}
