#name_create_work {
  border-radius: 7px;
  border: none;

  margin-bottom: 20px;
  padding-left: 10px;

  font-family: Rubik;
  font-size: calc(0.4vw + 0.4vh + 1vmin);
}

#descritption_work {
  resize: none;
  border-radius: 7px;
  border: none;

  padding-left: 10px;

  font-family: Rubik;
  font-size: calc(0.4vw + 0.4vh + 1vmin);
}

#background_create {
  margin: 30px;
  /* margin-left: 7vw; */
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.76);
}

#bubble_create_work {
  /* max-width: 100px; */
  width: 7vw;
  position: relative;
  top: -75px;
  right: 60px;
}

#wrapper_create {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
}

#work_create {
  padding: 20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#create_work {
  /* background-color: greenyellow; */
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#create_top {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 35%;
  width: 60%;
  /* background-color: red; */
}

#create_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  height: 55%;
  width: 80%;
  /* background-color: #000; */
}

#work_is_visible {
  color: #000;
  font-family: Verdana;
  font-size: calc(0.4vw + 0.4vh + 1vmin);
  font-weight: 400;
  line-height: normal;
}

.wrap_input_pictures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0px 10px;
  width: 50%;
  height: 75%;
  /* background-color: red; */
}

/* #file_upload{
  background-color: #59A1E0;

  width: 100%;
  height: 70%;

  border: none;
  border-radius: 20px;
  padding-top: 11%;
  text-align: center;
  vertical-align: center;
  cursor: pointer;

  color: #000000;
  font-family: Verdana;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin);
} */
.file-input {
  background: #ffcf3a;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);

  width: 100%;
  height: 70%;

  border: none;
  border-radius: 20px;
  /* padding-top: 11%; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: #000000;
  font-family: Rubik;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin);
}

.hw_create_buttons {
  background: #ffac3a;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);

  width: 100%;
  height: 70%;

  border: none;
  border-radius: 20px;

  padding: auto;
  text-align: center;
  vertical-align: center;
  cursor: pointer;

  color: #000000;
  font-family: Rubik;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin);
}

#under_button_sign {
  color: #000000;
  font-family: Rubik;
  font-size: calc(0.5vw + 0.5vh + 0.25vmin);
}

/*   
  .file-input input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  } */

@media screen and (max-width: 525px) {
  #create_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55%;
    width: 80%;
    /* background-color: rebeccapurple; */
  }

  .file-input,
  .hw_create_buttons {
    height: 50%;
  }

  .wrap_input_pictures {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0px;
    width: 100%;
    height: 75%;
  }
}
