.shop-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(306px, 1fr));
    gap: 40px 10px;
    justify-items: center;

}

.shop-page {
    padding-right: calc((100vw - 1280px)/2);
    padding-left: calc((100vw - 1280px)/2);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #A0DAF6;

    h1 {
        text-align: center;
    }
}

.card-shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 306px;
    width: 100%;
    padding: 10px;
    border: 6px solid #CEF96B;
    border-radius: 30px;
    transition: background-color .3s;
    background-color: #F3FAF9;

    footer {
        display: flex;
        justify-content: center;
        gap: 7px;
        align-items: center;
    }

}

.card-shop__title {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;

    h3 {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    p {
        font-size: 18px;
        font-weight: 200;
        font-style: italic;
    }

}

.card-shop__image {
    width: 100%;
    height: 250px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.card-shop-price {
    display: flex;
    align-items: center;
    gap: 7px;

    img {

        width: 34px;
        height: 34px;
    }

    p {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
    }
}

.card-shop__button {
    background: none;
    border: none;
    outline: none;

    &:active {
        transform: scale(0.98);
    }
}

.card-shop__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 48px;
    border-radius: 30px;
    background-color: #CEF96B;

}

.--choosen-count {
    background-color: #FFFFFF;
}

.--choosen {
    background-color: #CEF96B;
}

.shop-alert {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 60px;
    background-color: #CEF96B;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;

    h2 {
        color: black;
    }

}

.shop__gametokens {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(206, 249, 107, 0.7);
    position: fixed;
    bottom: 20px;
    right: 20px;

    p {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
    }

    img {
        width: 30px;

    }

}