.navbar {
  height: 5vh;
  background-color: white;
  position: absolute;
}

.container {
  max-width: 100vw;
  width: auto;
  height: 100%;

  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
}

#burger_button {
  position: absolute;
  top: 3px;
  left: 10px;
  /* margin: 0px auto 0px 10px; */
  background-color: transparent;
  border: none;

  display: none;
}

#style_burger_button {
  height: 20px;
}

.nav-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-elements ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 16px;
  /* font-size: calc(0.5vw + 0.5vh + 1vmin); */
  font-family: Rubik;
  font-weight: 700;
  color: #000000;
  text-decoration: none;
  text-wrap: nowrap;
}

.nav-elements ul a.active {
  color: black;
  font-weight: 700;
  position: relative;

  text-decoration: underline;
}

/* .nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
} */

@media screen and (max-width: 525px) {
  #burger_button {
    display: block;
    margin: 0px auto 0px 10px;
    cursor: pointer;
  }

  .nav-elements {
    z-index: 500;
    position: absolute;
    left: 0;
    top: 5vh;
    background-color: #ffffff;
    width: 0px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  body.nav-elements ul {
    overflow: hidden;
  }
 
  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav-elements ul li {
    margin-right: unset;
    
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
}
