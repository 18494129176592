.details {
    width: 100%;
    max-width: 768px;
    align-self: center;
    padding: 10px 20px;
    background: #03A5EA;
    border-radius: 30px;

    summary {
        color: white;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;

    }

    p {
        color: white;
        margin-top: 20px;
        font-weight: 500;
        font-size: 18px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

    }

    li {
        margin-top: 10px;
    }
}