.shop-page__cart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 40px;
    border-radius: 30px;
    background-color: #CEF96B;
    width: 100%;
    max-width: 850px;
}

.cart-item {
    display: flex;
    justify-content: space-between;
    padding: 21px 23px;
    border-radius: 30px;
    background-color: #F3FAF9;
    align-items: center;
    width: 100%;
    max-width: 650px;
    gap: 20px;

    p,
    h3 {
        margin: 0;
    }
}

.cart-item__title {
    font-size: 20px;
    font-weight: 700;
    color: black;
    width: 270px;
    white-space: nowrap;
    overflow-x: hidden;

    span {
        font-weight: 300;
    }
}

.cart-item__count {
    font-size: 20px;
    font-weight: 600;
    color: black;
    white-space: nowrap;
}

.cart-item__total {
    color: white;
    background: #03A5EA;
    border-radius: 30px;
    padding: 5px 21px;
    font-weight: 600;
    font-size: 20px;
}

.cart-item__button {
    border: none;
    outline: none;
    background: none;

    &:active {
        transform: scale(0.98);
    }
}

@media (max-width:420px) {
    .cart-list {
        padding: 10px;

        h3 {
            font-size: 18px;
            white-space: normal;
        }

        p {
            font-size: 16px;
        }
    }
}