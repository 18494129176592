/*styles for Gallery Item*/
/*styles for modal down below*/
#back_item {
  width: 355px;
  height: 400px;
  border-radius: 31px;
  background: rgba(255, 255, 255, 0.85);

  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 10px;
  transition: transform 0.3s ease-out;
}

#back_item:hover {
  cursor: pointer;
  transform: scale(1.009);
}

.hide_button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.hide_button:active {
  border: none;
  outline: none;
}

.about_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 3px 0px;
}

#pic {
  width: 25px;
  height: 25px;
  object-fit: cover;

  border-radius: 25px;
  background-color: rgb(255, 255, 255);
}

#user_name,
#user_surname,
#user_work_name {
  color: #000;
  font-family: Rubik;
  font-size: calc(0.35vw + 0.35vh + 1vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#left_arrow,
#right_arrow {
  background-color: transparent;
  width: 20px;
  height: 20px;
  padding: 0px 0px;
  border: none;
  /* border-radius: 50%;*/
  z-index: 2;
}

#left_arrow,
#left_arrow_modal {
  margin-right: 5px;
}

#right_arrow,
#right_arrow_modal {
  margin-left: 5px;
}

#pic_work {
  width: 300px;
  height: 300px;
  margin: 5px 0px;
  z-index: 1;
  object-fit: contain;
  /* background-image: url('./imgs/work_pic.png'); */
}

#heart,
#message {
  height: 20px;
}

#heart_count,
#message_count {
  margin-left: 10px;
}

#work_open {
  width: 100px;
  height: 100px;
  background-color: aqua;
}

#delete_work {
  margin: 0px 10px 0px auto;
}

#delete_work_button {
  height: 20px;
}

@media screen and (max-width: 525px) {
  #back_item {
    width: 300px;
    height: 345px;
    border-radius: 31px;
    background: rgba(255, 255, 255, 0.85);

    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 5px;
    transition: transform 0.3s ease-out;
  }

  #left_arrow,
  #right_arrow {
    width: 15px;
    height: 15px;
  }

  #pic_work {
    width: 250px;
    height: 250px;
    margin: 5px 0px;
    z-index: 1;
    object-fit: contain;
    /* background-image: url('./imgs/work_pic.png'); */
  }

  .about_item {
    margin: 2px 0px;
  }

  #user_name,
  #user_surname,
  #user_work_name {
    font-family: Verdana;
    font-size: calc(0.75vw + 0.55vh + 1vmin);
  }
}

/* MODAL STYLE */
/* MODAL STYLE */
/* MODAL STYLE */
#small_modal {
  display: none;
}

#modal_warning {
  color: #000;
  font-family: Verdana;
  font-size: calc(1vw + 2vh + 1vmin);
  text-align: center;
}

#modal_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

#comments_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100vh;
}

#about_item_modal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#about_item_stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#user_name_modal {
  color: #000;
  font-family: Verdana;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#user_surname_modal {
  color: #000;
  font-family: Verdana;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#right {
  padding: 10px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#delete_work_modal {
  margin-left: 10px;
}

.about_item_pic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
}

#name_work_modal {
  color: #000;
  font-family: Rubik;
  font-size: 24px;
}

#about_work_modal {
  color: #000;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
}

#left_arrow_modal {
  height: 50px;
  margin-right: 5px;
  z-index: 200;
}

#image_work_modal {
  z-index: 150;
}

#right_arrow_modal {
  height: 50px;
  margin-left: 5px;
  z-index: 200;
}

#comment {
  color: #000;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#wrap_write_comment {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

#write_comment {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#avatar_comment {
  width: 25px;
  height: 25px;
}

#input_comment {
  width: 90%;
  height: 25px;
  padding-left: 10px;

  color: #000;
  font-family: Verdana;
  font-size: 10px;

  background-color: white;
  border: 1px solid black;
  border-radius: 30px;
}

#close_modal_button {
  position: absolute;
  top: 15px;
  right: 10px;
}

#show_comment {
  margin-top: auto;
  color: blue;
  font-family: Verdana;
  font-size: 12px;
}

@media screen and (max-width: 525px) {
  #small_modal {
    /* width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    display: none;
  }

  #modal_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #left, #right {
    width: 100%;
    height: fit-content;
  }

  #vert_line{
    display: none; 
  }

  #comments_wrap{
    max-height: fit-content;
    height: auto;
  }

  #send_button {
    width: 20px;
    display: flex;
    align-self: center;
  }
  
  #left_arrow_modal, #right_arrow_modal, #left_arrow_modal_pic, #right_arrow_modal_pic {
    height: 40px !important; 
  }
  
}
