#background_hw {
  background-color: transparent;
  width: 100vw;
  padding: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap_hw {
  padding: 5px 0;
  width: 80vw;
  margin: 20px;
  border-radius: 35px;
  background: #fff;
  transition: transform 0.3s ease-out;
}

.wrap_hw:hover {
  transform: scale(1.009);
}

.task_short {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.task_long {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  /* background-color: rgb(255, 0, 0); */
}

.hw_buttons {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color:aqua; */
}

.label_homework {
  margin: 0px 10px;
  color: #000;
  font-family: Rubik;
  font-size: calc(1vw + 1.5vh + 0.75vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#homework_text {
  padding: 20px;
  margin: 0px 10px 20px 10px;
  color: #000;
  font-family: Rubik;
  font-size: calc(1vw + 1vh + 0.45vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.homework_bottom {
  margin: 0px 10px;
  color: #000;
  font-family: Rubik;
  font-size: calc(1vw + 1vh + 0.75vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.homework_bottom {
  background-color: transparent;
  border: none;
}

.homework_bottom:hover {
  cursor: pointer;
  text-decoration: underline;
}

.btn_homework {
  border: none;
  width: 35px;
  height: 35px;
  background-color: transparent;
  object-fit: cover;
}

#send_hw_button {
  margin-left: auto;
  background-color: #ffac3a;
  padding: 5px 20px;
  /* position: relative;
    right: 3px; */

  border: none;
  border-radius: 30px;

  font-family: Rubik;
  color: #fff;
  font-size: calc(1vw + 1vh + 0.75vmin);
  font-style: normal;
  font-weight: 400;
}

#work_name{
  font-family: Rubik;
  font-size: calc(1vw + 1vh + 0.75vmin);
  
  word-break: break-all;
}

@media screen and (max-width: 525px) {
  .task_long{
    flex-direction: column;
    align-items: flex-start;
  }

  .hw_buttons{
    
  }
}
