#teacher_hw_wrap {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

#right_teacher_hw {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 65%;
  border-radius: 25px;
  background: #d9d9d9;

  font-family: Verdana;
  font-size: calc(0.8vw + 0.5vh + 0.5vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#left_teacher_hw {
  width: 35%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#accept_hw {
  width: 150px;
  padding: 5px 15px;
  border: 1px solid black;
  border-radius: 20px;
  background-color: #ffffff;

  font-family: Verdana;
  font-size: calc(0.8vw + 0.5vh + 0.5vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 525px) {
  #teacher_hw_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #right_teacher_hw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
  }

  #left_teacher_hw {
    width: 100%;
    margin: 10px 0px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .check_hw {
    margin-left: 10px;
  }
}
