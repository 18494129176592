/*style for select and autocomplete, other styles below*/
.select_style {
  width: 43vw;
  height: 55px;
  padding: 0;
}

.autocomplete_style {
  width: 43vw;
  min-height: 45px;
  height: fit-content;
  margin: 10px 0px 25px 0px;
}

#admin {
  margin-left: 15px;
  color: #000;
  font-family: Verdana;
  font-size: calc(2vw + 2vh + 1vmin);
  font-weight: 700;
  line-height: normal;
}

#exit {
  color: #000;
  font-family: Verdana;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
}

.create_word {
  margin: 10px 0px 10px 0px;

  color: #000;
  font-family: Verdana;
  font-size: calc(1vw + 1.75vh + 2vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.all_input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 15px;
}

.input_admin {
  margin: 15px 5px 15px 0px;
  padding-left: 10px;
  width: 43vw;
  height: 45px;
  flex-shrink: 0;

  border: none;
  border-bottom: 1px solid #000;
}

.admin_search {
  margin: 15px 5px;
  padding-left: 10px;
  width: 400px;
  height: 43px;
  flex-shrink: 0;

  border: 1px solid black;
  border-radius: 30px;
  background-color: #ececec;
}

#gender {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin: 10px 5px;
  padding-left: 10px;
  width: 789px;
  height: 40px;
}

.name_gender {
  margin-right: 30px;
  color: #000;
  font-family: Verdana;
  font-size: 20px;
  font-style: normal;
}

.checkbox_gender {
  -webkit-appearance: none;
  background-color: #ffffff;
  border: 1px solid black;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);

  padding: 9px;
  margin-right: 5px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

/* .checkbox_gender:active, .checkbox_gender:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
} */

.checkbox_gender:checked {
  background-color: #ffffff;
  border: 1px solid #000000;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.checkbox_gender:checked:after {
  content: '\2714';
  font-size: 13px;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #000000;
}

#admin_head_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.admin_head_button {
  border: none;
  color: black;
  background-color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: Verdana;
}

.admin_head_button_active {
  border: none;
  color: black;
  background-color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: underline;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: Verdana;
}

.admin_head_button:hover {
  text-decoration: underline;
}

#admin_bottom_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-top: 10px;
}

.admin_submit_button {
  margin: 20px 0px 20px 0px;
  width: 280px;
  height: 45px;
  background-color: #00d076;
  border: none;
  border-radius: 60px;
  color: black;
  font-family: Verdana;
  font-size: 18px;
}

.admin_delete_button {
  margin: 20px 0px 20px 30px;
  width: 280px;
  height: 45px;
  background-color: #d00000;
  border: none;
  border-radius: 60px;
  color: black;
  font-family: Verdana;
  font-size: 18px;
}

#admin_avatar_wrap {
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.admin_avatar {
  display: flex;
  flex-direction: column;
}

#cost_word {
  margin-top: 15px;
  color: black;
  font-family: Verdana;
  font-size: 18px;
}

.input_admin_avatar {
  margin: 10px 5px 10px 0px;
  padding-left: 10px;
  width: 43vw;
  height: 45px;
  flex-shrink: 0;

  /* border: none; */
  border: 1px solid #a9a9a9;
  border-radius: 5px;
}

@media screen and (max-width: 525px) {
  .input_admin,
  .autocomplete_style,
  .select_style {
    width: 80vw;
  }

  #admin_bottom_buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    margin-top: 10px;
  }

  .admin_delete_button {
    margin: 0px 0px 20px 0px;
  }

  #admin_head_buttons {
    overflow-x: auto;
    width: 100%;
  }

  .admin_head_button_active, .admin_head_button {
    margin: 0;
    padding: 10px;
  }
}
