* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

#top_teacher {
  width: 100%;
  padding: 20px;
  height: 10%;
  color: #000;
  font-family: Verdana;
  /*font size for exit bttn that can be copied everywhere
    or put dirrectly in component*/
  font-size: calc(0.5vw + 0.5vh + 1vmin);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* border-bottom:1px solid #000; */
}

.group_name {
  border: 1px solid black;
}

#bot_teacher {
  width: 100%;
  display: flex;
  flex-direction: row;
}

#left_teacher {
  display: flex;
  flex-direction: column;

  padding: 20px;

  color: #000;
  font-family: Verdana;
  font-size: calc(0.5vw + 1vh + 0.75vmin);
  font-weight: 700;
  /* text-wrap: nowrap; */

  width: 25vw;
}

#right_teacher {
  display: flex;
  flex-direction: column;
  padding: 20px;

  color: #000;
  font-family: Verdana;
  font-size: calc(0.5vw + 1vh + 0.5vmin);
  font-weight: 700;
  line-height: normal;

  width: 75vw;
}

#pick_data {
  display: flex;
  flex-direction: row;
}

#pick_group {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

#my_group {
  margin: 10px 5px 0px 0px;
}

#data_picker {
  margin-left: 10px;
}

.teacher_name {
  color: #000;
  font-family: Verdana;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
}

.group_name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  padding: 5px 10px;
  height: fit-content;
  border-radius: 30px;
  background: #fff;
}

.group_name_picked {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
  padding: 10px 15px;
  width: 90%;
  height: fit-content;
  border-radius: 30px;
  background: #00d076;
}

#label_group {
  word-wrap: break-word;
  width: 100%;
  height: auto;
  font-size: 16px;
  padding: 5px;
}

#teacher_create_hw {
  display: flex;
  flex-direction: column;
}

.about_hw {
  padding: 10px 10px 10px 0px;
  margin: 10px 5px;
  padding-left: 10px;
  width: 100%;

  /* border: none;
    border-bottom: 1px solid #000; */
  border-radius: 20px;
  border: 1px solid #000;
  background: #f4f4f4;
}

#hw_button {
  /* background-color: palevioletred; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 10px 10px 20px auto;
}

.picked_file {
  font-family: Verdana;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#choose_file {
  /* background-color: #00D076; */
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#delete_hw_file {
  width: 230px;
  height: 40px;
  border: 1px solid black;
  border-radius: 25px;
  background-color: #dc3f0b;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Verdana;
  color: #fff;
  font-size: calc(0.8vw + 0.5vh + 0.5vmin);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/*style for add file button, need to be done through label*/
#choose_hw_file {
  width: 230px;
  height: 40px;
  cursor: pointer;
  margin-bottom: 3px;
  /* padding: 10px 55px; */

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: 1px solid black;
  border-radius: 25px;

  font-family: Verdana;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.send_hw_file {
  margin: 0px 10px;
  /* padding: 10px 55px; */
  width: 230px;
  height: 40px;
  background-color: #00d076;

  border: 1px solid black;
  border-radius: 25px;

  color: black;
  font-family: Verdana;
  font-size: calc(0.8vw + 0.5vh + 0.5vmin);
}

#all_tabs,
#teacher_hw {
  overflow-x: auto;
}

#teacher_hw::-webkit-scrollbar {
  display: none;
}

#all_tabs::-webkit-scrollbar {
  display: none;
}

#hw_text {
  width: 100%;
  resize: none;
  white-space: pre-wrap;
}

.underline_button {
  border: none;
  background-color: transparent;
}

.underline_button:hover {
  text-decoration: underline;
}

@media screen and (max-width: 525px) {
  #bot_teacher {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  #left_teacher {
    width: 100%;
  }

  #pick_data {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
  }

  #pick_group {
    margin: 0px 25px 0px 0px;
    width: 100%;
    overflow: auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  #pick_group::-webkit-scrollbar {
    display: none;
  }

  #my_group {
    margin: 0px 5px 0px 0px;
  }

  #right_teacher {
    font-size: calc(0.75vw + 0.75vh + 1vmin);
    width: 100%;
  }

  #hw_button {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #choose_hw_file {
    /* padding: 15px 0px 0px 0px; */
    font-size: calc(0.8vw + 0.65vh + 1vmin)
  }

  .send_hw_file {
    margin: 10px 0px 10px 0px;
    font-size: calc(0.8vw + 0.65vh + 1vmin)
  }
}