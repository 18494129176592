.button_default {
    background-color: #03A5EA;
    border-radius: 30px;
    font-size: 24px;
    font-weight: 600;
    color: white;
    outline: none;
    transition: transform 0.3s ease;
    border: none;
    padding: 12px 20px;

    &:active {
        transform: scale(0.98);
    }
}