#back {
  background: linear-gradient(
    150deg,
    #a0daf6 0%,
    #a0daf6 50%,
    #03a5ea 50%,
    #03a5ea 100%
  );
  width: auto;
  min-height: 100vh;
  height: fit-content;
  padding-bottom: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#works {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#header_gallery {
  margin-top: 30px;
  color: #000000;
  font-family: Rubik;
  font-size: calc(2vw + 2vh + 2vmin);

  /*font-size: calc(var(--index) * 2.5);*/
}
